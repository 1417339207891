import React, { useState } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from './Component/home/logo.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import home2 from './Component//home/homenew.jpg';
import home5 from './Component//home/home2.jpg';
import home3 from './Component//home/home3.jpg';
import family from './Component//home/family.jpg';
import brand1 from './Component/home/brand1.png';
import i1 from './Component/i1.png';
import carpet from './Component/product/h1.png';
import hardwood from './Component/product/h2.png';
import laminate from './Component/product/h3.png';
import Tile from './Component/product/h4.png';
import s2 from './Component/s2.png';
import hh1 from './Component/home/r1.png';
import hh2 from './Component/home/r2.png';
import hh3 from './Component/home/r3.png';
import Header from './Component/Header';
import Request1 from './Component/requestEstimate1';

function Home() {
	const [menuOpen, setMenuOpen] = useState(false);

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};

	return (
		<div >

			<div className="hero-wrap hero-wrap-2  "><Header /></div>
			<section >
				<div className="hero-wrap hero-wrap-2" >
						<div className="static h-full bg-opacity-50 md:grid md:grid-cols-2 " style={{ backgroundImage: `url(${home2})` }} data-stellar-background-ratio="0.5">
							<div  style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
							<div className=" inset-0 flex md:px-5  h-full p-3" >
								<div className="text-white text-center">
									<img
										src={family}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto md:w-[80%] rounded-xl"
									/>
									<p className="mt-4  font-extrabold md:text-[25px] text-[20px]">
										Explore our range of flooring and hardware products.
									</p>
									<a href='/about-us'> <div className='btn btn-warning w-[50%]'> Learn more</div> </a>
								</div>
							</div>
						</div>
						<div style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
							<div className=" inset-0 flex md:px-5 h-full items-center justify-center" >
								<Request1 />
							</div>
						</div>
							
						
					</div>
				</div>

			</section>
			{/* <section className="md:hero-wrap md:hero-wrap-2   md:block hidden " style={{ backgroundImage: `url(${home2})`, height: '100%' }}>
				<div className="overlay h-full " >
					<div className='font-sans md:pb-[425px]' style={{ background: 'rgba(0, 0, 0, 0.3)' }} >
						
						<div class=" ">
						<div className=' text-center pt-1.5 text-white  text-md font-bold ' style={{ backgroundColor: 'rgb(243, 127, 19)' }}><span>
				<p className='hidden md:flex md:font-[10px] text-center items-center justify-center font-bold'>
						<span
							className='font-bold cursor-pointer'
							onClick={() => {
								window.open('https://www.google.com/maps/place/Oliver+Flooring/@36.4495552,-81.7974365,15z/data=!4m6!3m5!1s0x88505d9e2bf2603f:0x8d2df70de7a3a77c!8m2!3d36.4495552!4d-81.7974365!16s%2Fg%2F11h12rj5tp?entry=ttu', '_blank');
							}}
						>
							2009 South Shady St Mountain City, TN 37683
						</span>
					<a href="tel:+18288651700" className='text-white font-semibold'>
				<i className="bi bi-telephone pl-5 text-white font-bold"> (828) 865-1700</i>
			</a></p></span></div>
							<div className="flex item-center  md:hidden justify-center pb-3" >
								<a href="/" class="d-block " ><img src={logo} class="h-[130px] "  alt="Los Angeles" /> </a>


							</div>
							<div class="flex items-center lg:order-2 pb-2 px-3">

								<button onClick={onToggleMenu}
									name={menuOpen ? 'close' : 'menu'}
									className="text-3xl cursor-pointer md:hidden">
									<span class="sr-only">Open main menu</span>
									<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
									<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

								</button>

							</div>
							<div class=" px-2">


								<div class="flex items-center justify-center  pb-2">

									<div class="hidden md:flex md:items-center">					</div>

									<div className=" hidden md:flex md:item-center justify-center " >
										<a href="/" class="d-block " ><img src={logo} class="h-[200px] " alt="Los Angeles" /> </a>


									</div>
									<div
										className={`nav-links duration-500 md:static   pt-2 text-white    left-0 ${menuOpen ? 'top-[9%]  relative' : 'top-[-100%] items-center justify-content-center absolute'
											} md:w-auto w-full flex  `}
									>
										<ul className="flex md:flex-row flex-col md:items-center  md:justify-content-center p-2  border-slate-700 font-sans">

											<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
												<button class="text-white   font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
													<i class="bi bi-x-square-fill"></i>
												</button>
											</li>
											<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-white"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<span className='font-bold'> PRODUCTS</span>
									</button>
									<ul className="dropdown-menu bg-black !text-white">
										<li>
											<a href="/products" class=" dropdown-item !text-white  hover:text-slate-900 hover:!bg-yellow-500 ">All Products</a>
										</li>
										<li>
											<a href="/products/carpet" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">Carpet</a>
										</li>
										<li>
											<a href="/products/hardwood" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">Hardwood</a>
										</li>
										<li>
											<a href="/products/laminate" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">Laminate</a>
										</li>
										<li>
											<a href="/products/carpet-tile" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">Carpet-tile</a>
										</li>
										<li>
											<a href="/products/waterproof_Flooring" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">Waterproof Flooring</a>
										</li>
										<li>
											<a href="/products/luxuryvinyl" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">luxury Vinyl</a>
										</li>
										<li>
											<a href="/products/vinyl" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">Vinyl</a>
										</li>
										<li>
											<a href="/products/cork" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">Cork</a>
										</li>
										<li>
											<a href="/products/Solid-Surface" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">Solid-Surface</a>
										</li>


									</ul>
								</div>
							</li>
							<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-white"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<span className='font-bold'> SERVICES</span>
									</button>
									<ul className=" dropdown-menu bg-black !text-white">
										<li>
											<a href="/flooring-services" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">All Services</a>
										</li>
										<li>
											<a href="/hardwood-refinishing" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">Hardwood refinishing</a>
										</li>
									</ul>
								</div>
							</li>
										
											<li>
												<a href="/reviews" class="text-white btn px-3 font-bold"><span className='font-bold'> REVIEWS</span></a>
											</li>
											<li>
												<a href="/financing" class="text-white  btn px-3"><span className='font-bold'> FINANCING</span></a>
											</li>
											<li>
												<a href="/inspiration" class="text-white  btn px-3"><span className='font-bold'> INSPIRATION</span></a>
											</li>
											<li>
												<a href="/about-us" class="text-white  btn px-3"><span className='font-bold'> ABOUT</span></a>
											</li>
											<li>
												<a href="/contact" class="text-white  btn px-3"><span className='font-bold '> CONTACT</span></a>
											</li>
											<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
												<a href="/free-estimate" class=" text-white  btn px-3"><span className='font-bold '>Request an Estimate</span></a>

											</li>


										</ul>
									</div>


									<div class="hidden  md:flex md:items-center px-3">
										<div><a href="/free-estimate" class=" text-white  btn btn-warning  px-3 py-2">Request </a></div>
									</div>


								</div>





							</div>




						</div>


					</div>
				</div>

			</section> */}

			<section className='  p-4' >
				<div className='container text-center'>
					<h1 class=" text-3xl   leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl "> LOOKING FOR NEW FLOORS IN THE BOONE, NC AREA?</h1>
					<p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl md:px-16 xl:px-48 warning:text-gray-400"> SHOP OUR EXTENSIVE FLOORING CATALOG</p>
					<div class="row flex justify-content-center ">
						<div class="col-sm-6 col-lg-4 col-12 p-2 rounded-[18px] justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2 rounded-[18px]" style={{ backgroundImage: `url(${carpet})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white  rounded-[20px]" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Carpet</div></h2>

												<a href='/products/carpet' > <div className='btn btn-warning '>Browse Carpet</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2 rounded-[18px] justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2 rounded-[18px]" style={{ backgroundImage: `url(${Tile})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white  rounded-[20px]" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Carpet Tile</div></h2>

												<a href='/products/carpet-tile' > <div className='btn btn-warning '>Browse Carpet-tile</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2 rounded-[18px] justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2 rounded-[18px]" style={{ backgroundImage: `url(${hardwood})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white  rounded-[20px]" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Hardwood</div></h2>

												<a href='/products/hardwood' > <div className='btn btn-warning '>Browse Hardwood</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2 rounded-[18px]  justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2 rounded-[18px]" style={{ backgroundImage: `url(${laminate})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white  rounded-[20px]" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>luxuryVinyl</div></h2>

												<a href='/products/luxuryvinyl' > <div className='btn btn-warning '>Browse luxuryvinyl</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2 rounded-[18px]  justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2 rounded-[18px]" style={{ backgroundImage: `url(${home2})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white  rounded-[20px]" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Laminate</div></h2>

												<a href='/products/laminate' > <div className='btn btn-warning '>Browse Laminate</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2 rounded-[18px]  justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2 rounded-[18px]" style={{ backgroundImage: `url(${hardwood})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white  rounded-[20px]" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Cork</div></h2>

												<a href='/products/cork' > <div className='btn btn-warning '>Browse Cork</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>

					</div>
					<a href='/products' className='flex items-center justify-center'> <div className='btn w-[25%] btn-light'> <span className='no-underline font-bold hover:text-slate-900'>View All Products </span></div> </a>

				</div>



			</section>
			
			<section >
			<div class="container pt-3">
				<a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

			</div>


			</section>
			<section class="bg-[#E38A02] sm:h-[80vh] sm:max-h-[500px] sm:mt-[50px]">
				<div className='container '>
					<div class="row flex  ">
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 ">
						<img src={home5} alt="Los Angeles" class=" sm:h-[90vh] sm:max-h-[550px] sm:relative sm:top-[-30px]" style={{ borderRadius: '6%' }} />
					</div>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
						paddingInline: '5% 10%'
					}}>
							<div className='p-2 text-white text-left ' >
								<p class=" font-bold  text-2xl">Commitment to excellence in Boone, NC</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo">
											<div class="card-body-c align-self-left">
												<p class="content-c">Here at Oliver Flooring We want to help you pick the right floor to insure you are 100% happy. We pride ourselves on being high quality specialist that will get the job done right the fist time.</p><p class="lead">
													<a href="/about-us" class="btn btn-warning   btn-lg text-white active" role="button" style={{ borderRadius: '8px' }}>Explore More About Us</a>
												</p>

											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
			<section class="p-4 " style={{ backgroundColor: '#ffffff' }}>
				<div className='container'>
					<div class="row flex content-center">
						<div class="col-md-4 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%', borderBlockColor: '#E38A02' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={hh1} alt="Los Angeles" class="d-block rounded-[18px]" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=""> Reviews</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c ">
									The Boone, NC area trusts Oliver Flooring for all of their flooring needs.
									</p>
									<a href='/reviews'> <div className='btn btn-dark w-100 text-white' style={{ backgroundColor: '#E38A02', borderRadius: '18px' }}> Our Reviews</div> </a>
								</div>
							</div>

						</div>
						<div class="col-md-4 col-sm-6 col-6 p-1 col-12" >
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={hh2} alt="Los Angeles" class="d-block rounded-[18px]" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" "> Request</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c  ">
									Request your accurate and professional in-home flooring estimate.
									</p>
									<div class="mt-auto">
										<a href='/free-estimate' class='btn btn-dark w-100 text-white' style={{ backgroundColor: '#E38A02', borderRadius: '18px' }}>Schedule Now</a>
									</div>								</div>
							</div>

						</div>
						<div class="col-md-4 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={hh3} alt="Los Angeles" class="d-block rounded-[18px]" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" "> Services</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c ">
									Our professional flooring installers are ready to help you with your next project.
									</p>
									<a href='/flooring-services'> <div className='btn btn-dark w-100 text-white border-[#E38A02]' style={{ backgroundColor: '#E38A02', borderRadius: '18px' }}> what we Offer</div> </a>
								</div>
							</div>

						</div>
					</div>
				</div>

			</section>

			<section className='hidden md:flex md:items-center md:justify-center md:px-[13%]'>
				<div class="  col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 rounded-3xl">
					<section className="hero-wrap hero-wrap-2 rounded-3xl" style={{ backgroundImage: `url(${carpet})`, height: '100%' }}>
						<div className="overlay h-full rounded-3xl" style={{ background: 'rgba(0, 0, 0, 0.7)', opacity: 1 }} >

							<div className="container ">
								<div className="row no-gutters slider-text align-items-end p-5 justify-content-center" >
									<div className=" ftco-animate mb-2 text-center py-4 text-white " >
										<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Shop In-Store</div></h2>
										<p class="content-c">Our knowledgeable staff is ready to assist you in selecting the right flooring to ensure your satisfaction for years to come.</p>

										<a href='/contact' > <div className='btn  text-white w-fit' style={{ backgroundColor: '#E38A02', borderRadius: '8px' }}>Contact Us</div> </a>
									</div>
								</div>
							</div>
						</div>

					</section>
				</div>
				<div class=" col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 rounded-3xl">
					<section className="hero-wrap hero-wrap-2 rounded-3xl" style={{ backgroundImage: `url(${hardwood})`, height: '100%' }}>
						<div className="overlay h-full rounded-3xl" style={{ background: 'rgba(0, 0, 0, 0.7)', opacity: 1 }} >

							<div className="container ">
								<div className="row no-gutters slider-text align-items-end p-5 justify-content-center" >
									<div className=" ftco-animate mb-2 text-center py-4 text-white " >
										<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Shop At Home</div></h2>
										<p class="content-c">Don't have time to stop by our showroom? We can bring samples right to your front door. Just schedule an appointment to get started.</p>

										<a href='/contact' > <div className='btn  text-white w-fit' style={{ backgroundColor: '#E38A02', borderRadius: '8px' }}>Get Started</div> </a>
									</div>
								</div>
							</div>
						</div>

					</section>
				</div>

			</section>

			
			
			<section class="bg-[#E38A02] sm:h-[80vh] sm:max-h-[500px] sm:mt-[50px]">
				<div className='container '>
					<div class="row flex  ">
					
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
						paddingInline: '5% 10%'
					}}>
							<div className='p-2 text-white text-left ' >
								<p class=" font-bold  text-2xl">Why should you choose us?</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo">
											<div class="card-body-c align-self-center">
											<p class="content-c py-3"><ul>
											<li className='py-2'>Family owned & operated</li>
											<li className='py-2'>Helpful staff</li>
											<li className='py-2'>Highest quality products</li>
											<li className='py-2'>In-house design consultants</li>
											<li className='py-2'>Knowledgeable staff</li>
												<li className='pt-2'>Licensed installers</li>
											</ul></p>
											<p class="lead">
												<a href="/free-estimate" class="btn btn-warning  btn-lg active text-white" role="button" style={{ borderRadius: '8px' }}>Request an Estimate</a>
											</p>

											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 ">
						<img src={home3} alt="Los Angeles" class=" sm:h-[90vh] sm:max-h-[550px] sm:relative sm:top-[-30px]" style={{ borderRadius: '6%' }} />
					</div>
					</div>
					
				</div>

			</section>
			
			
			
			<div className='mt-4'><Request /></div>
			<section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Featured brands & technologies</span>

				</div>
				<section>
					<div >
						<img
							src={brand1}
							alt="brands"
							className="mx-auto "
						/>
					</div>
				</section>

			</section>
			<div><Footer /></div>


		</div>
	);
}

export default Home;
